import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

export default function MultiActionAreaCard(props) {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <Card sx={{ maxWidth: 400 }} style={{marginTop: "3rem",  marginLeft: "auto", marginRight: "auto"}} >
      <CardActionArea>
        <CardMedia
          component="img"
          height="250"
          width="350"
          image={props.image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div" color="var(--blue)">
            {props.title}
          </Typography>
          <Typography style={{textAlign: "justify", color: "var(--light-color)", padding:"0.5rem 1rem", fontSize: "1.4rem",lineHeight: "2", overflow: "hidden" }}>
            {showMore ? props.description : `${props.description.substring(0, 220)}...`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button className="btn" onClick={() => setShowMore(!showMore)} size="large" color="primary">
          en savoir plus ...
        </Button>
      </CardActions>
    </Card>
  );
}