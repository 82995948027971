import React from 'react';
import './Argum.css';
import { images } from '../../Constants';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Argum = () => {

  return (
    <section className="about">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-4 image">
                    <img className='whyChoose' src={images.WhyChoose}  alt="WhyChooseimg"/>

                   
                </div>
                
                <div className="col-md-8 content">
                    <span>POURQUOI NOUS CHOISIR</span>
                    <h3>Nous traitons votre maison ou votre société Comme si c’était la notre</h3>
                    <p>La satisfaction de nos clients est notre priorité absolue - nous nous engageons à vous fournir un service exceptionnel et à répondre à tous vos besoins</p>
                        <div className='containerAuthor'>
                            <img src={images.Avatar} alt='Avatar' />
                            <h4>12 ans d'expérience</h4>
                            <button disabled className="link-btn">Expert</button>
                        </div>

                    
                </div>
            </div>
        </div>
    </section>
  )
}

export default Argum