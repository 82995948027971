import React, { useState } from 'react';
import './Navbar.css';
import {images} from '../../Constants';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { red } from '@mui/material/colors';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <a href="#home"><img src={images.LogoControl} className="logo" alt="LogoControl"/></a>
      
      <a href="https://wa.me/33763060101" target='blank' className="link-btn">Nous contacter<br/>07 63 06 01 01</a>
      <div className="hamburger" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }>
        <ul>
          <li>
            <a href="#home" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>Accueil</a>
          </li>
          <li>
            <a href="#about" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>A propos de nous</a>
          </li>
          <li>
            <a href="#services" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>Services</a>
          </li>
          <li>
            <a href="#testimonials" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>Témoignages</a>
          </li>
          <li>
            <a href="#footer" onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}