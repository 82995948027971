import React from 'react';
import './App.css';
import About from './components/About/About';
import Argum from './components/Argum/Argum';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Services from './components/Services/Services';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <React.Fragment>
      <Navbar/>
      <Home/>
      <About/>
      <Services/>
      <Argum/>
      <Testimonials />
      <Footer/>
    </React.Fragment>
     
  );
}

export default App;
