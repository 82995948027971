import React from 'react';
import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
const Footer = () => {
  return (
    <div id='footer' className='footer'>
      <div className='sb__footer section__padding'>
        <div className="sb__footer-links">
          <div className="sb__footer-links_div_prop">
            <h1>A propos de SoSHygieneControle</h1>
            <h3>Société spécialisée en Dératisation – Désinsectisation - Désinfection – Dépigeonnisation.</h3>
            <h4>Spécialiste dans la lutte contre les punaises de lit et les nuisibles pour particuliers et professionnels</h4>
          </div>
          <div className="sb__footer-links_div">
            <h4>Menu Principal</h4>
            <a href="Accueil">
              <p>Accueil</p>
            </a>
            <a href="A propos de nous">
              <p>A propos de nous</p>
            </a>
            <a href="Services">
              <p>Services</p>
            </a>
            <a href="Temoignages">
              <p>Témoignages</p>
            </a>
            <a href="Contact">
              <p>Contact</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Nos Services</h4>
            <a href="/">
              <p>Punaises</p>
            </a>
            <a href="/">
              <p>Insectes</p>
            </a>
            <a href="/">
              <p>Rats et souris</p>
            </a>
            <a href="/">
              <p>Désinfection</p>
            </a>
            <a href="/">
              <p>Pigeons</p>
            </a>
            <a href="/">
              <p>Autres nuisibles</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Contactez nous</h4>
              <p><BusinessIcon/><br />159 Bd Jean Jaurès, Clichy 92110</p>
              <p><EmailIcon/><br />soshygienecontrole@gmail.com</p>
              <p><CallIcon/><br />0763060101</p>
              <p><LanguageIcon/><br/>www.soshygienecontrol.com</p>
          </div>
          <div className="sb__footer-links_div">
            <h4>Coming soon on</h4>
            <div className="socialmedia">
              <FacebookIcon sx={{fontSize: 50 }}/>
              <InstagramIcon sx={{fontSize: 50 }}/>
              <WhatsAppIcon sx={{fontSize: 50 }}/>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="sb__footer-below">
          <div className="sb__footer-below-copyright">
            <p>
              @{new Date().getFullYear()} SOSHYGIENECONTROLE. All Right Reversed.
            </p>
          </div>
          <div className="sb__footer-below-links">
            <a href="/terms"><div><p>Terms & Conditions</p></div></a>
            <a href="/privacy"><div><p>Privacy</p></div></a>
            <a href="/security"><div><p>Security</p></div></a>
            <a href="/cookie"><div><p>Cookie Declaration</p></div></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer