import React from 'react';
import MultiActionAreaCard from './PrestCard';
import "./Services.css";
import { ServicesObject } from './ServicesObject';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Services = () => {
  return (
    <section className="services" id="services">
        <h2>Nos prestations</h2>
        <h3>Nous fournissons le meilleur service</h3>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {ServicesObject.map((service)=>(
                <Grid item xs={4} sm={4} md={4} key={service.id}>
                  <MultiActionAreaCard image={service.image} logo={service.logo} title={service.title} description={service.description}  />
                </Grid>
              ))}
          </Grid>
        </Box>

    </section>
  )
}

export default Services