import React from 'react'
import "./Testimonials.css"
import TestimonialsCard from './TestimonialsCard'

const Testimonials = () => {
  return (
    <section className="testimonials" id="testimonials">
        <h2>Témoignages</h2>
        <h3>Ce que nos clients disent de nous</h3>
        <TestimonialsCard/>
    </section>
  )
}

export default Testimonials