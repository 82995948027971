import Logo from '../images/Logo.svg';
import LogoControl from '../images/LogoControl.png';
import MenuIcons from '../images/MenuIcon.svg';
import HomeBg from '../images/HomeBg.webp';
import Desinfection from '../images/Desinfection.jpg';
import DesinLogo from '../images/DesinLogo.png';
import Certifications from '../images/Certifications.png';
import TermiteLogo from '../images/TermiteLogo.png';
import CafardLogo from '../images/CafardLogo.png';
import RatLogo from '../images/RatLogo.png';
import Cafard from '../images/Cafard.jpg';
import Rat from '../images/Rat.jpg';
import PunaiseLogo from '../images/PunaiseLogo.png';
import Punaise from '../images/Punaise.jpg';
import Termite from '../images/Termite.jpg';
import Pigeons from '../images/Pigeons.jpg';
import RightQuote from "../images/RightQuote.png";
import WhyChoose from "../images/WhyChoose.jpg";
import Avatar from "../images/Avatar.png";


// eslint-disable-next-line
export default {Logo, MenuIcons, HomeBg, Certifications, RightQuote, LogoControl, Desinfection, DesinLogo, TermiteLogo, Termite, Cafard, CafardLogo, Rat, RatLogo, Punaise, PunaiseLogo, Pigeons, WhyChoose, Avatar};