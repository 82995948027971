import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "./TestimonialsCard.css";
import {images} from '../../Constants';
// import required modules
import { EffectCoverflow, Pagination } from "swiper";


const TestimonialsCard = () => {
  return (
    <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="testimonialBox">
            <img src={images.RightQuote} className="quote" alt="quote1"/>
            <div className="content">
                <p>Equipe très professionnelle, à l’écoute, attentive et dévouée. Je suis ravie des résultats immédiats obtenus pour mon restaurant.</p>
                <div className="details">
                    <div className="imgBx">
                        <AccountCircleIcon sx={{fontSize: 50 }}/>
                    </div>
                    <h3>Sophia Patel<br/><span>Paris-2ème</span></h3>
                </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonialBox">
            <img src={images.RightQuote} className="quote" alt="quote1"/>
            <div className="content">
                <p>Le technicien qui est intervenu chez moi a été à la fois rapide et ponctuel. Il a pris le temps de me fournir des explications détaillées sur le problème, démontrant ainsi sa minutie et sa patience.</p>
                <div className="details">
                    <div className="imgBx">
                    <AccountCircleIcon sx={{fontSize: 50 }}/>
                    </div>
                    <h3>Benjamin Anderson<br/><span>Clichy</span></h3>
                </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonialBox">
            <img src={images.RightQuote} className="quote" alt="quote1"/>
            <div className="content">
                <p>Grâce à votre intervention rapide et efficace, je suis enfin libéré de ces punaises de lit. Je tiens à vous exprimer une gratitude infinie pour votre précieuse aide.</p>
                <div className="details">
                    <div className="imgBx">
                    <AccountCircleIcon sx={{fontSize: 50 }}/>
                    </div>
                    <h3>Ethan Garcia<br/><span>Paris-8ème</span></h3>
                </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonialBox">
            <img src={images.RightQuote} className="quote" alt="quote1"/>
            <div className="content">
                <p>Je recommande vivement, très pro, rapide et efficace !!</p>
                <div className="details">
                    <div className="imgBx">
                    <AccountCircleIcon sx={{fontSize: 50 }}/>

                    </div>
                    <h3>Isabella Wilson<br/><span>Clamart</span></h3>
                </div>
            </div>
          </div>
        </SwiperSlide>
    </Swiper>
  )
}

export default TestimonialsCard