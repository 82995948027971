import React from 'react';
import './Home.css';
import {images} from '../../Constants';
const Home = () => {
  return (
    <section className="home" id="home">
        <div className="container">
            <div className="row min-vh-100 align-items-center">
                <div className="content text-center text-md-left">
                    <h3>Dératisation Désinsectisation Désinfection <br/><span>Solutions Anti Nuisibles</span></h3>
                    <p>7J / 7 - Paris & Idf — Eradication garantie à 100% ! — Intervention possible sous 2h</p>
                    <img src={images.Certifications} alt="quote1"/>
                    <a href="#about" className="link-btn">Devis Gratuit</a>
                    
                </div>
            </div>
        </div>
    </section>
  )
}

export default Home